<template>
  <b-card class="mt-3" header-tag="header">
    <template #header>
      <div>
        <div class="child inline-block-child">
          <img
            v-b-tooltip.hover
            title="Network"
            width="20px"
            src="../../../assets/images/networking.svg"
          />
        </div>
        <div class="child inline-block-child">
          <h6 class="mb-0">Network</h6>
        </div>
      </div>
    </template>
    <b-form-group
      label="Bridge:"
      label-for="nested-street"
      label-cols-sm="2"
      label-align-sm="right"
    >
      <v-select
        v-model="network_selected"
        :loading="busy"
        :label="network.label"
        :options="network"
      ></v-select>
    </b-form-group>
  </b-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      network: [],
      busy: true
    };
  },
  created: function () {
    this.getNetwork();
  },
  computed: {
    network_selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async getNetwork() {
      var response = await this.$apiCloud("nodes/node1/network", {
        method: "GET",
      });
      response.data.forEach((lan) => {
        this.network.push({
          value: lan["vlan-id"],
          label: lan.iface + "-" + lan.comments,
        });
        this.network.sort(function (a, b) {
          var nameA = a.label.toUpperCase();
          var nameB = b.label.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      });
      this.busy = false;
    },
  },
};
</script>
<style scoped>
.inline-block-child {
  display: inline-block;
  margin-right: 10px;
}
</style>
